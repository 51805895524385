import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgSetCycle01 from '../generated/svg/set-cycle-01'
import SvgSetGraph01 from '../generated/svg/set-graph-01'
import SvgSetCycle02 from '../generated/svg/set-cycle-02'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { SlideNavigator } from '../components/slide-navigator'
import { AnimateArrow } from '../components/animate-arrow'
import { useBreakpoint } from '../hooks/use-break-point'
import { AnimateStep } from '../components/animate-step'

const Outer = styled.div`
  height: 400vh;
  background-color: #F4F4F4;
  position: relative;
  @media (max-width: 40rem){
    height: auto;
    padding: 4rem 0 8rem;
  }
`

const Inner = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @media (max-width: 40rem){
    position: static;
    width: auto;
    height: auto;
  }
  .swiper-slide {
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    align-items: center;
  }
`

const ItemContainer = styled.div``

const Item = styled.div`
  max-width: 72rem;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 5fr 5fr;
  background-color: #F4F4F4;
  align-items: center;
  gap: 4rem;
  padding-left: 8rem;
  @media (max-width: 60rem){
    grid-template-columns: 1fr;
    gap: 2.5rem;
    max-width: 40rem;
  }
  @media (max-width: 40rem){
    padding-left: var(--page-margin);
    margin-top: 8rem;
    scroll-margin-top: 4rem;
  }
`

const GraphContainer = styled.div`
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const TextContainer = styled.div`
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.06em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1rem;
    line-height: 1.9375;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
    margin-top: 1rem;
  }
  small {
    display: block;
    font-size: 0.8em;
  }
`

const NavigatorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 3;
  @media (max-width: 40rem){
    position: sticky;
    top: 2.5rem;
    transform: none;
    left: auto;
    display: flex;
    justify-content: center;
    height: 0;
    min-width: 100%;
  }
`

type SetCycleSectionProps = {

}
export const SetCycleSection: FunctionComponent<SetCycleSectionProps> = () => {
  const {ref, activeIndex, slideTo, swiper} = useSwiperClass()
  const outerRef = useRef<HTMLDivElement>(null)
  const isMobile = useBreakpoint('40rem')
  useEffect(() => {
    const outer = outerRef.current
    if(outer && swiper){
      const listener = () => {
        const rect = outer.getBoundingClientRect()
        const index = Math.floor(Math.max(0, Math.min(2, (rect.top - window.innerHeight / 2) / window.innerHeight * -1)))
        if(swiper.realIndex !== index){
          swiper.slideTo(index)
        }
      }
      const observer = new IntersectionObserver(entries => {
        const [entry] = entries
        if(entry?.isIntersecting){
          window.addEventListener('scroll', listener)
        } else {
          window.removeEventListener('scroll', listener)
        }
      })
      observer.observe(outer)
      return () => {
        observer.disconnect()
        window.removeEventListener('scroll', listener)
      }
    }
  }, [swiper])

  const [activeIndexMobile, setActiveIndexMobile] = useState(0)
  const innerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const inner = innerRef.current
    if(inner){
      const elements = Array.from(inner.children)
      const observer = new IntersectionObserver(entries => {
        const entry = entries.findLast(entry => entry.isIntersecting)
        const index = elements.findIndex(el => el === entry?.target)
        if(index > -1){
          setActiveIndexMobile(index)
        }
      }, {threshold: 0.5})
      elements.forEach(el => observer.observe(el))
      return () => {
        observer.disconnect()
      }
    }
  }, [isMobile])
  const items = [
    <Item>
      <GraphContainer>
        <SvgSetCycle01 />
      </GraphContainer>
      <TextContainer>
        <Text1>
          ブランド推奨のメソッドで<br />
          効果的なホームケア
        </Text1>
        <Text2>
          肌荒れを起こしにくく、より効果的にレチノール<sup>※</sup>を使う方法としてアメリカを中心に世界的に流行しているスキンケア方法を、<br />
          日本人の肌に合うようにアレンジしたのがSKN REMEDの”Skin Cycle Method”です。<br />
          <small>※ 整肌成分</small>
        </Text2>
      </TextContainer>
    </Item>
  ]

  return <Outer ref={outerRef}>
    <Inner>
      <NavigatorContainer>
        <SlideNavigator
          labels={[
            {
              label: <>
                SKIN CYCLE<br />
                METHOD
              </>
            }
          ]}
          activeIndex={isMobile ? activeIndexMobile : activeIndex}
          onChange={index => {
            if(isMobile){
              const inner = innerRef.current
              if(inner){
                inner.children[index]?.scrollIntoView({behavior: 'smooth'})
              }
            } else {
              const outer = outerRef.current
              if(outer){
                const rect = outer.getBoundingClientRect()
                window.scrollTo({top: (rect.top + window.scrollY) + rect.height / 3 * index})
              }
            }
          }}
        />
      </NavigatorContainer>
      {
        isMobile ? <ItemContainer ref={innerRef}>
          {
            items.map((item, i) => {
              return <Fragment key={i}>
                {item}
              </Fragment>
            })
          }
        </ItemContainer> : <>
          <Swiper
            modules={[
              EffectFade
            ]}
            effect="fade"
            fadeEffect={{
              crossFade: true
            }}
            preventInteractionOnTransition
            onSwiper={ref}
            speed={1000}
          >
            {
              items.map((item, i) => {
                return <SwiperSlide key={i}>
                  {item}
                </SwiperSlide>
              })
            }
          </Swiper>
        </>
      }
    </Inner>
  </Outer>
}