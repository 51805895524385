import React, { ComponentProps, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { AssetImage } from './image'
import { SvgMinus, SvgPlus } from './svg'
import { Bar } from './bar'
import { isNotNullish } from '../utils/type-check'
import classNames from 'classnames'

const Outer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.4s;
  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: auto;
    height: 16rem;
  }
`

const Type = styled.div`
  font-size: 0.6875rem;
  border: 1px solid currentColor;
  margin-top: 0.5rem;
  padding: 0.25rem;
  min-width: 6rem;
  text-align: center;
`

const Name = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
`

const Text1 = styled.div`
  font-size: 0.875rem;
  margin-top: 1rem;
  color: #A0A0A0;
`

const BarContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
`

const Button = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10rem;
  width: 100%;
  border: 1px solid currentColor;
  transition: background-color 0.4s;
  cursor: pointer;
  user-select: none;
  @media (max-width: 40rem){
    padding: 0.75rem 1.5rem;
    font-size: 0.8rem;
  }
  &:hover {
    background-color: #fff;
  }
  svg {
    height: 1.5rem;
    width: auto;
    display: block;
    aspect-ratio: 1 / 1;
    @media (max-width: 40rem){
      height: 1rem;
    }
  }
`
const Button2 = styled(Button)`
  flex: 0 0 auto;
  justify-content: center;
  aspect-ratio: 1 / 1;
  height: 3rem;
  width: 3rem;
  padding: 0;
  transition: opacity 0.4s;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const Qty = styled.div`
  font-size: 1.25rem;
  width: 3rem;
  text-align: center;
`

type SetProductItemProps = {
  data: {
    id: string | number,
    productId?: number | string | undefined,
    subsId?: number,
    img: string,
    type: ReactNode,
    name: ReactNode,
    volume: ReactNode,
    price: number | null,
    color: string,
    bar: ComponentProps<typeof Bar>['values']
  }
  disabled: boolean
  qty: number
  addable: boolean
  onAdd: (data: SetProductItemProps['data']) => void
  onSubtract: (data: SetProductItemProps['data']) => void
  singleOnly?: boolean
  selectLabel?: ReactNode
  unSelectLabel?: ReactNode
}
export const SetProductItem: FunctionComponent<SetProductItemProps> = ({data, disabled, qty, addable, onAdd, onSubtract, singleOnly, selectLabel, unSelectLabel}) => {
  return <Outer className={classNames({disabled})}>
    <ImageContainer>
      <AssetImage src={data.img} />
    </ImageContainer>
    <Type>
      {data.type}
    </Type>
    <Name>
      {data.name}
    </Name>
    <Text1>
      {data.volume}
      {isNotNullish(data.price) ? ` 定価 ${data.price.toLocaleString('en-US')}円(税込)` : null}
    </Text1>
    <BarContainer>
      {
        data.bar.length > 1 && <Bar values={data.bar} />
      }
    </BarContainer>
    <ButtonContainer>
      {
        qty > 0 && !singleOnly ? <>
          <Button2 onClick={() => onSubtract(data)}>
            <SvgMinus size={8}/>
          </Button2>
          <Qty>
            {qty}
          </Qty>
          <Button2 className={classNames({disabled: !addable})} onClick={() => onAdd(data)}>
            <SvgPlus size={8}/>
          </Button2>
        </> : <>
          <Button onClick={() => qty > 0 ? onSubtract(data) : onAdd(data)}>
            {qty > 0 ? unSelectLabel : selectLabel ?? '追加する'}
            {
              qty > 0 ? <SvgMinus /> : <SvgPlus />
            }
          </Button>
        </>
      }
    </ButtonContainer>
  </Outer>
}