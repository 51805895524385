import * as React from "react";
import type { SVGProps } from "react";
const SvgSetGraphD03 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 124 100"
    {...props}
  >
    <defs>
      <clipPath id="svg-4bd3f20d-a">
        <path fill="none" d="M0 0h124v100H0z" />
      </clipPath>
    </defs>
    <g fill="none" clipPath="url(#svg-4bd3f20d-a)">
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="m78.188 33.02-1.776-2.654a10.75 10.75 0 0 1-1.823-5.964v-5.385H46.65v5.385c0 2.106-.63 4.172-1.824 5.964l-1.775 2.654a8.9 8.9 0 0 0-1.509 4.925v34.166c0 3.79 8.545 6.855 19.077 6.855s19.077-3.075 19.077-6.855V37.945a8.9 8.9 0 0 0-1.509-4.925"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M72.203 15.188c-2.94-1.224-7.037-1.988-11.582-1.988s-8.64.764-11.581 1.988h-4.602v4.593c0 3.633 7.246 6.58 16.174 6.58s16.174-2.947 16.174-6.58v-4.593h-4.602z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M60.62 21.768c8.933 0 16.174-2.946 16.174-6.58s-7.241-6.58-16.174-6.58-16.175 2.946-16.175 6.58 7.242 6.58 16.175 6.58"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M60.621 19.281c6.908 0 12.508-2.017 12.508-4.504s-5.6-4.505-12.508-4.505-12.508 2.017-12.508 4.504 5.6 4.505 12.508 4.505"
      />
      <path fill="#fff" d="M48.856 34.009s4.573 3.466 15.85 2.242z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M48.856 34.009s4.573 3.466 15.85 2.242"
      />
      <path fill="#fff" d="M66.824 36.046s2.779-.157 4.25-.921z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M66.824 36.046s2.779-.157 4.25-.921"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M87.44 42.744s-4.04.48-5.749 2.889c0 0-3.37.49-4.067 3.76 0 0-3.208 1.694-3.237 5.15 0 0-3.905 4.104-2.234 6.19 0 0-3.256 6.57-.917 9.312 1.337 1.567.163.862 3.533.206s10.808-5.082 10.808-5.082 4.784-3.447 5.624-3.957 3.017 16.98-1.747 23.835l1.852.02s4.25-5.141 1.547-24.658c0 0 11.181-7.03 14.924-11.653 3.742-4.622-.239-5.17-3.438-5.023 0 0-2.024-1.606-5.557-1.116 0 0-1.957-1.44-4.755-.157 0 0-3.399-2.145-6.578.313z"
      />
      <path
        fill="#fff"
        d="M104.168 50.01s-3.953 1.273-8.622 4.328c-.354.118-4.87.196-8.851 4.916 0 0-2.1 1.292-4.335 4.837 0 0-.582.118-1.986 1.528s-2.568 2.36-3.39 2.585"
      />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M104.168 50.01s-3.953 1.273-8.622 4.328c-.354.118-4.87.196-8.851 4.916 0 0-2.1 1.292-4.335 4.837 0 0-.582.118-1.986 1.528s-2.568 2.36-3.39 2.585"
      />
      <path fill="#fff" d="M84.23 47.934s3.791 4.319 4.01 6.58z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M84.23 47.934s3.791 4.319 4.01 6.58"
      />
      <path fill="#fff" d="M87.106 51.655s-2.654-1.567-6.149.069z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M87.106 51.655s-2.654-1.567-6.149.069"
      />
      <path fill="#fff" d="M87.88 56.18s-6.234-3.262-10.56.264z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M87.88 56.18s-6.234-3.262-10.56.264"
      />
      <path fill="#fff" d="M84.85 56.63s-4.755-.637-10.952 5.973z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M84.85 56.63s-4.755-.637-10.952 5.973"
      />
      <path
        fill="#fff"
        d="M85.539 58.421s-3.132.088-4.545 2.458-4.459 5.905-4.803 5.905"
      />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M85.539 58.421s-3.132.088-4.545 2.458-4.459 5.905-4.803 5.905"
      />
      <path fill="#fff" d="M93.063 46.348s-2.683 1.283-3.418 7.109z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M93.063 46.348s-2.683 1.283-3.418 7.109"
      />
      <path fill="#fff" d="M99.316 47.356s-2.426-.852-5.691.9z" />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M99.316 47.356s-2.426-.852-5.691.9"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M103.232 57.07s2.129-1.36 6.159.255c0 0 2.32-.568 3.848.43 1.527 1 3.456.823 4.602 3.007 0 0 3.074.813 3.437 3.584 0 0 1.528 1.4 1.308 4.358 0 0 .764.998.926 3.956 0 0 .755 2.38-.42 4.338 0 0 .363 2.771-1.757 3.339 0 0 .554 2.575-1.766 3.937 0 0-1.174 2.751-3.676 2.536 0 0-2.521 1.948-5.204.548 0 0-2.138 2.154-4.239.558 0 0-2.511.764-3.647-1.018 0 0-4.049.95-4.793-2.213 0 0-3.466-.225-3.247-3.193 0 0-2.788-.812-1.604-3.868 0 0-2.692-.812-1.213-4.455 0 0-2.08-4.162.43-5.729 0 0 .038-4.935 4.469-5.297 0 0 .238-5.327 6.397-5.083z"
      />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M101.047 66.03s7.39 3.026 11.677 8.99M113.285 76.116s1.814 2.086 1.404 5.053M104.004 69.027s3.523 4.28 3.504 7.432M107.5 77.252l.172 2.174M107.47 80.513s.372 2.076-.802 3.545M101.027 67.617s1.137 1.988 1.022 4.651l-.506 3.35M101.248 76.41s-.305 2.566-2.053 3.937M103.098 73.365s1.432 1.988 1.317 3.76M100.262 66.912s-.125 3.26-2.149 3.936M101.629 64.757s2.988-.274 3.876-3.124M111.016 70.76c0-.294 3.198-2.536 8.096-.725"
      />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M115.836 69.82s3.351 2.007 3.237 4.074M105.465 66.96s6.932.353 10.331-3.466"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M43.663 72.444C19.678 72.444.238 76.95.238 82.501v7.756h.067c1.232 5.297 20.175 9.498 43.358 9.498s42.125-4.21 43.357-9.498h.067V82.5c0-5.552-19.44-10.057-43.424-10.057"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M43.665 91.099c22.706 0 41.113-3.723 41.113-8.314S66.371 74.47 43.664 74.47 2.551 78.193 2.551 82.785s18.407 8.314 41.113 8.314"
      />
      <path
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M61.48 80.386c8.48.95 15.115 2.556 18.447 4.514M50.031 79.564c4.068.146 7.916.43 11.448.822M37.504 79.554a180 180 0 0 1 9.548-.079M25.406 80.435c2.282-.265 4.707-.48 7.228-.646M7.16 85.047c2.52-1.566 7.152-2.918 13.167-3.907"
      />
      <path
        fill="#eae5f1"
        stroke="#9f86af"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M57.688 84.822c0 1.586-2.187 2.879-4.88 2.879s-4.878-1.293-4.878-2.879 2.186-3.858 4.879-3.858 4.879 2.272 4.879 3.858ZM44.998 85.84c0 1.274-1.747 2.302-3.905 2.302s-3.906-1.028-3.906-2.301 1.748-3.085 3.906-3.085 3.905 1.812 3.905 3.085Z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="m108.225 2.799-1.231-1.188a4.803 4.803 0 0 0-6.91.217L88.982 13.932c-1.85 2.016-1.755 5.19.211 7.087l1.231 1.187a4.803 4.803 0 0 0 6.91-.216l11.102-12.104c1.85-2.017 1.756-5.19-.211-7.087Z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M101.143 23.17c2.501-2.723 1.489-7.874-2.263-11.487-3.753-3.624-8.823-4.348-11.324-1.616l-3.724 4.054 2.922 2.82c.592 1.616 1.623 3.232 3.065 4.612 1.441 1.381 3.074 2.35 4.678 2.85l2.922 2.82z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M97.429 27.225c2.5-2.726 1.485-7.87-2.268-11.491-3.752-3.62-8.821-4.346-11.321-1.62s-1.486 7.87 2.267 11.491 8.822 4.346 11.322 1.62"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M92.654 24.334c1.48-1.615 1.403-4.152-.172-5.67a3.84 3.84 0 0 0-5.529.177L58.682 49.667s-.44.108-2.73 4.113c-1.643 2.87-3.18 4.465-3.916 5.141a3 3 0 0 0-.468.402c-1.002 1.096-.954 2.81.115 3.838a2.597 2.597 0 0 0 4.163-.744c.659-.842 2.11-2.448 4.688-4.22 2.97-2.047 3.638-2.762 3.79-2.968.02-.02.048-.039.068-.058l28.271-30.827z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M54.756 62.691c-.362.402-1.7.558-2.482-.196s-.707-2.134-.334-2.526 1.298-.118 2.072.636c.773.754 1.117 1.685.744 2.086"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M92.654 24.334c1.48-1.615 1.403-4.152-.172-5.67a3.84 3.84 0 0 0-5.529.177L58.682 49.667s-.44.108-2.73 4.113c-1.643 2.87-3.18 4.465-3.916 5.141a3 3 0 0 0-.468.402c-1.002 1.096-.954 2.81.115 3.838a2.597 2.597 0 0 0 4.163-.744c.659-.842 2.11-2.448 4.688-4.22 2.97-2.047 3.638-2.762 3.79-2.968.02-.02.048-.039.068-.058l28.271-30.827z"
      />
      <path
        fill="#eae5f1"
        stroke="#9f86af"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M69.441 43.244c-1.556-.588-2.97-.637-3.16-.098l-7.009 7.56h-.047c-.239.303-.898 1.184-2.311 3.652-1.71 2.987-3.323 4.68-4.144 5.415l-.057.05-.067.048c-.114.078-.2.157-.277.225a1.61 1.61 0 0 0-.076 2.057l-.42.274s.42 5.023-.21 6.296c-.573 1.146-2.683 6.933 1.365 9.01.964.499 2.167.097 2.673-.872.459-.881.831-2.223.592-4.21-.372-3.076-1.584-5.073-1.584-6.474 0-.871.047-2.84.076-4.2.019-.03.029-.05.048-.079l.047-.108.077-.098c.678-.861 2.215-2.585 4.936-4.455 2.96-2.037 3.466-2.644 3.514-2.693l.057-.078.143-.137 8.116-8.843c.086-.059.162-.117.191-.206.19-.538-.917-1.449-2.473-2.036Z"
      />
      <path
        fill="#fff"
        stroke="#9680a4"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M29.17 54.847h.067l-4.564-13.043a1.157 1.157 0 0 0-2.196 0l-4.564 13.043h.067a6 6 0 0 0-.43 1.773c-.276 3.388 2.473 6.58 5.787 6.717s6.292-2.693 6.292-6.198c0-.833-.163-1.596-.449-2.292z"
      />
    </g>
  </svg>
);
export default SvgSetGraphD03;
