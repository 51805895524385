import * as React from "react";
import type { SVGProps } from "react";
const SvgRetinoDermGraph01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 439.526 180.094"
    {...props}
  >
    <defs>
      <clipPath id="svg-dc571cc1-a">
        <path
          fill="none"
          d="M.25 94.163V72.25c18.61 0 21.54 12.174 40.182 12.174s21.54-12.174 40.18-12.174c17.504 0 22.039 10.754 40.182 12.174 15.865 1.233 21.118-8.709 35.66-11.565a23.9 23.9 0 0 1 8.884-.047c14.605 2.622 19.779 11.612 35.77 11.612 17.348 0 21.089-10.551 36.519-12.002a28.5 28.5 0 0 1 7.014.218c15.414 2.373 20.449 11.784 36.814 11.784 18.033 0 21.369-11.409 38.45-12.127 1.03-.047 2.074 0 3.103.109v21.913c-1.03-.109-2.073-.156-3.102-.109-17.082.733-20.402 12.127-38.451 12.127-16.365 0-21.4-9.411-36.814-11.784a28.5 28.5 0 0 0-7.014-.218c-15.446 1.467-19.187 12.002-36.518 12.002-15.992 0-21.166-8.974-35.77-11.612a24.3 24.3 0 0 0-8.884.047c-14.542 2.84-19.795 12.798-35.661 11.565-18.127-1.42-22.663-12.174-40.181-12.174-18.626 0-21.572 12.174-40.181 12.174S18.86 94.163.25 94.163"
        />
      </clipPath>
    </defs>
    <path
      fill="#fff"
      stroke="#e98760"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M.25 94.627V72.714c18.61 0 21.54 12.174 40.182 12.174s21.54-12.174 40.18-12.174c17.504 0 22.039 10.753 40.182 12.174 15.865 1.233 21.118-8.709 35.66-11.566a24 24 0 0 1 8.884-.046c14.605 2.622 19.779 11.612 35.77 11.612 17.348 0 21.089-10.551 36.519-12.003a28.6 28.6 0 0 1 7.014.219c15.414 2.372 20.449 11.784 36.814 11.784 18.033 0 21.369-11.409 38.45-12.127 1.03-.047 2.074 0 3.103.109v21.913c-1.03-.109-2.073-.156-3.102-.109-17.082.733-20.402 12.127-38.451 12.127-16.365 0-21.4-9.412-36.814-11.784a28.6 28.6 0 0 0-7.014-.219c-15.446 1.468-19.187 12.003-36.518 12.003-15.992 0-21.166-8.975-35.77-11.612a24.3 24.3 0 0 0-8.884.046c-14.542 2.841-19.795 12.799-35.661 11.566-18.127-1.421-22.663-12.174-40.181-12.174-18.626 0-21.572 12.174-40.181 12.174S18.86 94.627.25 94.627Z"
    />
    <g
      fill="none"
      stroke="#e98760"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      clipPath="url(#svg-dc571cc1-a)"
    >
      <path d="m5.097-30.058-74.05 165.191M12.391-30.058l-74.065 165.191M19.669-30.058l-74.065 165.191M26.948-30.058l-74.05 165.191M34.228-30.058l-74.05 165.191M41.52-30.058l-74.065 165.191M48.8-30.058l-74.05 165.191M56.079-30.058l-74.05 165.191M63.374-30.058l-74.065 165.191M70.652-30.058l-74.05 165.191M77.93-30.058 3.88 135.133M85.226-30.058 11.16 135.133M92.503-30.058l-74.05 165.191M99.783-30.058l-74.05 165.191M107.077-30.058 33.012 135.133M114.355-30.058l-74.05 165.191M121.634-30.058l-74.05 165.191M128.929-30.058 54.864 135.133M136.206-30.058l-74.05 165.191M143.486-30.058l-74.05 165.191M150.78-30.058 76.714 135.133M157.888-30.058 83.821 135.133M165.167-30.058l-74.05 165.191M172.445-30.058l-74.05 165.191M179.74-30.058l-74.067 165.191M187.019-30.058l-74.066 165.191M194.296-30.058l-74.05 165.191M201.59-30.058l-74.065 165.191M208.87-30.058l-74.066 165.191M216.15-30.058 142.1 135.133M223.427-30.058l-74.05 165.191M230.722-30.058l-74.066 165.191M238-30.058l-74.049 165.191M245.279-30.058l-74.05 165.191M252.574-30.058l-74.067 165.191M259.853-30.058l-74.05 165.191M267.13-30.058l-74.05 165.191M274.425-30.058 200.36 135.133M281.704-30.058l-74.05 165.191M288.982-30.058l-74.05 165.191M296.277-30.058 222.21 135.133M303.556-30.058l-74.05 165.191M310.663-30.058l-74.05 165.191M317.943-30.058l-74.05 165.191M325.236-30.058 251.17 135.133M332.515-30.058l-74.05 165.191M339.794-30.058l-74.05 165.191M347.087-30.058l-74.065 165.191M354.367-30.058l-74.05 165.191M361.646-30.058l-74.05 165.191M368.94-30.058l-74.065 165.191M376.218-30.058l-74.05 165.191M383.497-30.058l-74.05 165.191M390.792-30.058l-74.065 165.191" />
    </g>
    <path
      fill="rgba(233, 135, 96, .2)"
      stroke="#e98760"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M.905 107.784c18.61 0 20.9 11.487 39.527 11.487s21.54-12.174 40.18-12.174c17.504 0 22.039 10.754 40.182 12.174 15.865 1.233 21.118-8.709 35.66-11.565a23.9 23.9 0 0 1 8.884-.047c14.605 2.622 19.78 11.612 35.77 11.612 17.348 0 21.089-10.551 36.519-12.002a28.5 28.5 0 0 1 7.014.218c15.415 2.373 20.449 11.784 36.814 11.784 18.033 0 21.369-11.409 38.45-12.127 1.03-.047 2.074 0 3.103.109.545 2.217.389 4.214.389 6.493 0 36.506-72.335 66.098-161.55 66.098S.297 150.252.297 113.746c0-2.013.218-3.996.654-5.962z"
    />
    <path
      fill="rgba(233, 135, 96, .1)"
      stroke="#e98760"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M161.152.25C78.514.25 10.371 25.628.755 58.388c-.28.968.374 1.967 1.371 2.03 16.506.982 19.982 12.095 37.796 12.095s21.54-12.174 40.181-12.174c17.504 0 22.04 10.754 40.182 12.174 15.866 1.233 21.118-8.709 35.646-11.565 2.93-.578 5.952-.578 8.898-.047 14.604 2.622 19.763 11.612 35.77 11.612 17.349 0 21.089-10.55 36.52-12.002 17.939-1.577 25.857 12.158 43.828 12.002 17.83.094 22.74-12.174 39.12-12.143 1.044 0 1.792-1.03 1.481-2.029C311.886 25.612 243.758.25 161.169.25z"
    />
    <path
      fill="#e98760"
      d="M380.866 55.25v-9.8h2.156v7.84h4.886v1.96zm7.828 0 4.2-9.87h1.987l4.2 9.87h-2.254l-.896-2.198h-4.144l-.896 2.198zm3.863-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894v3.906zm7.263 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32v1.918zm9.38 0v-9.8h4.48c1.245 0 2.211.35 2.855.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694v3.136zm2.155-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zm13.474 5.04v-7.686l-1.708.42-.448-1.764 2.814-.84h1.47v9.87z"
    />
    <circle cx={295.19} cy={49.75} r={3.5} fill="#e98760" />
    <path fill="none" stroke="#e98760" d="M297.69 49.75h77" />
    <path
      fill="#e98760"
      d="M380.866 95.25v-9.8h2.156v7.84h4.886v1.96zm7.828 0 4.2-9.87h1.987l4.2 9.87h-2.254l-.896-2.198h-4.144l-.896 2.198zm3.863-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894v3.906zm7.263 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32v1.918zm9.38 0v-9.8h4.48c1.245 0 2.211.35 2.855.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694v3.136zm2.155-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zm11.668 5.04v-1.708l3.262-2.674c1.218-1.008 1.694-1.54 1.694-2.352 0-.826-.546-1.274-1.316-1.274-.756 0-1.274.42-2.002 1.316L432.4 87.34c.966-1.316 1.904-2.03 3.654-2.03 2.03 0 3.374 1.19 3.374 3.024v.028c0 1.638-.84 2.45-2.576 3.794l-1.596 1.232h4.27v1.862z"
    />
    <circle cx={295.19} cy={89.75} r={3.5} fill="#e98760" />
    <path fill="none" stroke="#e98760" d="M297.69 89.75h77" />
    <path
      fill="#e98760"
      d="M380.866 137.25v-9.8h2.156v7.84h4.886v1.96zm7.828 0 4.2-9.87h1.987l4.2 9.87h-2.254l-.896-2.198h-4.144l-.896 2.198zm3.863-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894v3.906zm7.263 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32v1.918zm9.38 0v-9.8h4.48c1.245 0 2.211.35 2.855.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694v3.136zm2.155-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zm15.336 5.208c-1.764 0-2.982-.7-3.822-1.694l1.484-1.414c.672.756 1.372 1.176 2.366 1.176.812 0 1.386-.462 1.386-1.19v-.028c0-.798-.714-1.246-1.918-1.246h-.896l-.336-1.372 2.338-2.352h-3.85v-1.848h6.622v1.624l-2.478 2.366c1.33.224 2.632.924 2.632 2.744v.028c0 1.848-1.344 3.206-3.528 3.206"
    />
    <circle cx={295.19} cy={131.75} r={3.5} fill="#e98760" />
    <path fill="none" stroke="#e98760" d="M297.69 131.75h77" />
  </svg>
);
export default SvgRetinoDermGraph01;
